<template>
  <div id="Content">
    <div class="content-inner">
      <div v-if="pendingInvites.length > 0" class="invites">
        <h3>Pending Invites</h3>
        <user-permissions-invite v-for="invite in pendingInvites" :invite="invite" :key="invite.id"
          @submit="onHandleInvite" />
        <div class="divider"></div>
      </div>

      <div class="loading" v-if="loading">
        <loader site="planners" :active="loading" />
      </div>
      <div class="other-brands" v-if="otherBrands.length > 0">
        <h2>Additional Brands</h2>

        <div class="brand-container" v-for="(brand, index) in otherBrands" :key="index">
          <brand-tiles :name="brand.companyInfo.companyName" :brand="brand" :totalEvents="brand.events"
            :url="brand.pageInfo.subdomain" :latestDate="brand.latestEvent" @delete="onDeleteBrand" />
        </div>
      </div>
      <div v-else>
        No Brands
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import BrandTiles from "../../components/brand/BrandTiles.vue";
import UserPermissionsInvite from "../../components/permissions/UserPermissionsInvite.vue";

export default {
  name: "additional-brand",
  components: {
    BrandTiles,
    Loader,
    UserPermissionsInvite
  },
  head() {
    return {
      title: "Additional Brand"
    };
  },
  data() {
    return {
      loading: true,
      invites: [],
      otherBrands: []
    };
  },
  computed: {
    pendingInvites() {
      return this.invites
        ? this.invites.filter(i => !i.accepted)
        : this.invites;
    },
    acceptedInvites() {
      return this.invites ? this.invites.filter(i => i.accepted) : this.invites;
    },
    activeBrand() {
      return this.$store.state.activeBrand;
    }
  },
  mounted() {
    this.loadBrands();
    this.fetchBrandInvites();
    this.$store.dispatch(
      "updateHelpVideoSrc",
      "https://www.youtube.com/embed/gmUkPdOhC7A"
    );
  },

  methods: {
    async loadBrands() {
      const { data: { isActiveBrand, activeBrand } } = await this.$axios.get("/company/get-active-brand");
      this.loading = true;
      this.$axios.post("/company/get-brands").then(({ data }) => {
        let otherBrands = [];
        data.otherBrands.forEach(element => {
          if (element.length > 0) {
            otherBrands.push(element[0]);
          }
        });

        this.otherBrands = otherBrands;

        this.loading = false;

        if (isActiveBrand) {
          this.$store.dispatch("changeBrand", activeBrand);
          return;
        }

        if (!this.activeBrand || Object.keys(this.activeBrand).length === 0) {
          if (otherBrands.length > 0) {
            this.$store.dispatch("changeBrand", {
              name: otherBrands[0].companyInfo.companyName,
              subdomain: otherBrands[0].pageInfo.subdomain
            });
          } else {
            this.$store.dispatch("changeBrand", null);
          }
        }
      });
    },
    fetchBrandInvites() {
      this.$axios.post("/invites").then(response => {
        const data = response.data;
        this.invites = data.invites;
      });
    },
    onHandleInvite(invite, accepted) {
      const index = this.invites.indexOf(invite);

      if (index > -1) {
        if (accepted) {
          this.invites[index].accepted = true;
          this.loadBrands();
        } else {
          this.invites.splice(index, 1);
          this.$store.state.user.plannerAddedBrands--;
          if (!this.hasAnyBrands()) {
            this.$store.dispatch("changeBrand", null);
            this.$router.push("/id-verification");
          }
        }
      }
    },
    onDeleteBrand(brandId) {
      const index = this.otherBrands
        .map(function (b) {
          return b.permissionId.$oid;
        })
        .indexOf(brandId);

      if (index > -1) {
        this.otherBrands.splice(index, 1);
        this.$store.state.user.plannerAddedBrands--;
      }

      if (!this.hasAnyBrands()) {
        this.$store.dispatch("changeBrand", null);
        this.$router.push("/id-verification");
      }
    },
    hasAnyBrands() {
      return this.otherBrands.length > 0 || this.pendingInvites.length > 0;
    },
    toBrandStats() {
      this.$router.push("/brand-stats");
    }
  },
  beforeDestroy() {
    this.$store.dispatch("updateHelpVideoSrc", null);
  }
};
</script>
<style lang="less" scoped></style>
