<template>
  <div id="Content">
    <div class="content-inner">
      <div class="loading" v-if="loading">
        <loader site="planners" :active="loading" />
      </div>
      <div class="main-brands" v-else>
        <div class="brands" v-if="brands.length > 0">
          <div
            class="brand-container"
            v-for="(brand, index) in brands"
            :key="index"
          >
            <brand-tiles
              :name="brand.companyInfo.companyName"
              :brand="brand"
              :totalEvents="brand.events"
              :url="brand.pageInfo.subdomain"
              :latestDate="brand.latestEvent"
            />
          </div>
        </div>
        <div class="row brands" v-else>
          <div class="col flex full center">
            <div>No Brands Added</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DiyoboButton from "@/components/DiyoboButton.vue";
import Loader from "@/components/Loader.vue";
import BrandTiles from "../../components/brand/BrandTiles.vue";

export default {
  name: "my-brand",
  components: {
    DiyoboButton,
    BrandTiles,
    Loader
  },
  head() {
    return {
      title: "My Brands"
    };
  },
  data() {
    return {
      loading: true,
      eventList: [],
      brands: [],
      currentEvent: "",
      invites: []
    };
  },
  computed: {
    activeBrand() {
      return this.$store.state.activeBrand;
    },
    pendingInvites() {
      return this.invites
        ? this.invites.filter(i => !i.accepted)
        : this.invites;
    }
  },
  async created() {
    await this.fetchInvites();
    await this.loadBrands();

    this.$store.dispatch(
      "updateHelpVideoSrc",
      "https://www.youtube.com/embed/gmUkPdOhC7A"
    );

    setTimeout(() => {
      if (
        this.$route.query.home &&
        this.$store.state.user.plannerBrands === 1
      ) {
        this.$router.push("/dashboard");
      }
    }, 500);
  },
  methods: {
    async loadBrands() {
      const {
        data: { isActiveBrand, activeBrand }
      } = await this.$axios.get("/company/get-active-brand");
      this.loading = true;
      this.$axios.post("/company/get-brands").then(({ data }) => {
        if (
          (data.userBrands.length === 0 && data.otherBrands.length > 0) ||
          (data.userBrands.length === 0 && this.pendingInvites.length > 0)
        ) {
          this.loading = false;
          this.$router.push("additional-brand");
        }
        this.brands = data.userBrands;
        this.loading = false;

        if (isActiveBrand) {
          this.$store.dispatch("changeBrand", activeBrand);
          return;
        }

        if (!this.activeBrand || Object.keys(this.activeBrand).length === 0) {
          if (data.userBrands.length > 0) {
            this.$store.dispatch("changeBrand", {
              name: data.userBrands[0].companyInfo.companyName,
              subdomain: data.userBrands[0].pageInfo.subdomain
            });
          } else {
            this.$store.dispatch("changeBrand", null);
          }
        }
      });
    },
    toBrandStats() {
      this.$router.push("/brand-stats");
    },
    fetchInvites() {
      this.$axios.post("/invites").then(response => {
        const data = response.data;
        this.invites = data.invites;
      });
    }
  },
  beforeDestroy() {
    this.$store.dispatch("updateHelpVideoSrc", null);
  }
};
</script>
<style lang="less" scoped>
.brands {
  padding: 16px 0;
}
</style>
