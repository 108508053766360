<template>
  <div class="invite">
    <div class="left">
      <span class="name">{{ invite.invited_by }} - {{ invite.brand }}</span>
      <span class="date">{{ formatDate(invite.invited_at) }}</span>
    </div>

    <div v-if="invite.accepted" class="right">
      <diyobo-button type="secondary" txt="Leave" @click="leave" />
    </div>
    <div v-else class="right">
      <diyobo-button type="primary" txt="Accept" @click="accept" />
      <diyobo-button type="secondary" txt="Deny" @click="deny" />
    </div>
  </div>
</template>

<script>
import DiyoboButton from "@/components/DiyoboButton.vue";
import dateformat from "dateformat";
import ModalContent from "@/helpers/modals";

export default {
  name: "UserPermissionsInvite",
  components: {
    DiyoboButton
  },
  props: {
    invite: Object
  },
  methods: {
    async accept() {
      await this.$axios.post("/permissions/invite/accept", {
        id: this.invite.id
      });

      this.$emit("submit", this.invite, true);
    },
    deny() {
      this.$axios.post("/permissions/invite/deny", {
        id: this.invite.id
      });

      this.$emit("submit", this.invite, false);
    },
    leave() {
      const content = ModalContent.leaveUserPermissions;
      this.$bus.$emit("alert", {
        msg: content.msg.replace("{invitee}", this.invite.invited_by),
        confirm: content.confirm,
        closable: true,
        onConfirm: () => {
          this.$axios
            .post("/permissions/invite/delete", {
              id: this.invite.id
            })
            .then(() => {
              this.$emit("submit", this.invite, false);
            });
        }
      });
    },
    formatDate(dateStr) {
      return dateformat(parseInt(dateStr), "ddd, mmm dS, yyyy");
    }
  }
};
</script>

<style lang="less" scoped>
.invite {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border: 1px solid var(--dashboard-border);
  border-radius: 5px;
  box-shadow: var(--table-shadow);

  .left {
    display: flex;
    flex-direction: column;

    .name {
      font-weight: bold;
    }
  }

  .right {
    display: flex;

    button {
      margin: auto 0 auto 16px;
    }
  }
}
</style>
