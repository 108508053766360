<template>
  <modal title="Leave Brand" :active="active" v-on:close="close">
    <div>Are you sure?</div>
    <br />

    <div>
      If you decide to leave this brand you'll lose all access and will require
      the event planner to add you back.
    </div>
    <template v-slot:footer>
      <div class="button-wrapper">
        <diyobo-button type="primary" txt="Leave Brand" v-on:click="submit" />

        <diyobo-button
          type="secondary"
          txt="Don't Leave Brand"
          v-on:click="close"
        />
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import DiyoboCheckbox from "@/components/DiyoboCheckbox.vue";

export default {
  name: "leave-brand-modal",
  components: {
    Modal,
    DiyoboButton,
    DiyoboCheckbox
  },
  data() {
    return {
      active: false,
      event: "",
      keepListed: false
    };
  },
  methods: {
    open(args = {}) {
      this.onConfirm = args.onConfirm;
      this.event = args.event;
      // this.$refs.modal.style.display = 'block'
      document.body.style.overflow = "hidden";

      setTimeout(() => {
        this.active = true;
      }, 250);
    },
    close() {
      this.active = false;
      setTimeout(() => {
        document.body.style.overflow = "initial";
        document.body.style.position = "relative";
      }, 500);
    },
    submit() {
      this.close();

      this.onConfirm();

      // this.$axios
      //   .post("/permissions/delete", {
      //     event: this.event,
      //     keepListed: this.keepListed,
      //   })
      //   .then((response) => {

      //   });
    }
  }
};
</script>

<style lang="less" scoped>
.modal {
  .modal-inner {
    .modal-dialog {
      .modal-body {
        .keep-listed {
          padding-top: 10px;
        }
        .modal-footer {
          .button-wrapper {
            display: flex;
            flex-wrap: wrap;
            row-gap: 10px;
            column-gap: 15px;
          }
          @media screen and (max-width: 600px) {
            .button-wrapper {
              width: 100%;
              justify-content: center;
              button {
                // max-width: 50%;
                padding: 10px;
              }
            }
          }
        }
      }
    }
  }
}
</style>