<template>
  <div class="live-event">
    <leave-brand-modal ref="leaveModal" />

    <div class="event-inner">
      <div class="left">
        <div class="event-details">
          <div class="brand-img">
            <img :src="brandImage" alt="" />
          </div>
          <div class="brand-details">
            <div class="brand-name">
              <a class="brand-name" target="_blank">{{ name }}</a>
            </div>
            <div class="more-details">
              <div class="event-date">
                Next event on {{ latestEventDate || "N/A" }}
              </div>
              <div class="creation-date" v-if="!brand.plannerName">
                Created on {{ creationDate || "N/A" }}
              </div>
              <div class="creation-date" v-else>
                Added by {{ brand.plannerName }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end left -->
      <div class="right">
        <div class="tix-sold">
          <div class="tix-sold-number">{{ totalEvents || 0 }}</div>
          <div class="tix-sold-label">Total Events</div>
        </div>
        <button v-on:click="changeBrand">Select Brand</button>

        <div class="event-options" v-if="brand.added">
          <span>Select Brand Options</span>
          <event-options
            class="live-events-options"
            id="event-menu"
            :options="addedBrandOptions"
            v-on:redirect="redirect"
            :eventUrl="brand.pageInfo.subdomain"
            :url="brand.pageInfo.subdomain"
            @cancel="onLeave"
          />
        </div>
        <div class="event-options" v-else>
          <span>Select Brand Options</span>
          <event-options
            class="live-events-options"
            id="event-menu"
            :options="options"
            v-on:redirect="redirect"
            :eventUrl="brand.pageInfo.subdomain"
            :url="brand.pageInfo.subdomain"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.live-event {
  margin: 0.75em auto;

  // border: 1px solid #333b3f;

  .event-inner {
    border-radius: 8px;
    box-shadow: 1px 1px 2px -1px #ffffff36, inset 0px 1px 5px 0px #00000069;
    // background-image: linear-gradient(#1f1f1f, #161616);
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 52px;

    .left {
      // width: calc(100% - 400px);
      height: inherit;
      margin: 20px 0;

      .event-details {
        padding: 0 20px;
        position: relative;
        // width: 100%;
        height: 100%;
        display: flex;
        // flex-direction: column;
        justify-content: space-between;
        flex-wrap: wrap;

        .brand-img {
          width: 160px;
          height: 160px;
          margin-right: 20px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .brand-details {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        @media screen and (max-width: 730px) {
          .brand-img {
            margin: auto;
            margin-bottom: 20px;
          }

          .brand-details {
            margin: 0 14px;
          }
        }

        a.brand-name {
          color: var(--text);
          font-size: 1.75em;
          font-weight: 700;
          word-break: break-word;
          line-height: 1em;
        }

        .event-date {
          margin: 10px 0;
          font-weight: 600;
          text-transform: uppercase;
          font-size: 1.05em;
          line-height: 1em;
        }

        .creation-date {
          position: relative;
          // bottom: -48px;
          color: var(--headers);
          font-size: 1.05em;
          font-weight: 600;
          text-transform: uppercase;
          line-height: 1em;
        }
      }
    }

    // end left
    .right {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      width: 380px;
      margin-left: 20px;

      .tix-sold {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 250px;
        padding: 5px 1px;
        // max-height: 101px;
        // height: 64px;
        margin: 10px 0;
        margin-bottom: 0;

        border: 1px solid var(--dashboard-border);
        /* border-top: none; */
        border-radius: 10px;
        background-image: var(--dashboard-tiles);
        box-shadow: var(--dashboard-shadow);

        .tix-sold-number {
          margin-left: 10px;
          font-size: 3em;
          font-weight: 700;
          color: var(--primary-green);
        }

        .tix-sold-label {
          padding: 5px 8px;
          // width: 75px;
          font-size: 15px;
          font-weight: 700;
          line-height: 100%;
          text-transform: uppercase;
        }
      }

      button {
        max-height: 111px;

        margin: 10px;
        margin-bottom: 0;
        padding: 3px 0;
        width: 105px;
        min-height: 50px;
        // height: 66px;
        font-family: inherit;
        font-size: 1.25em;
        font-weight: 700;
        line-height: 1em;
        text-transform: uppercase;
        color: #ffffff;
        border: none;
        border-radius: 10px;
        background: var(--secondary-green);
        -webkit-appearance: none;
        outline: none;
        cursor: pointer;
      }

      // end button
      &::v-deep .event-options {
        // color: #828282;
        /* padding-top: 3px; */
        background-image: var(--dropdown-background);
        // border: 1px solid var(--dropdown-border);
        box-shadow: var(--dashboard-shadow);
        width: 100%;
        align-items: center;
        border-radius: 10px;
        height: 50px;
        position: relative;
        display: flex;
        margin: 10px 10px 10px 0;

        span {
          width: 100%;
          height: fit-content;
          /* text-align: center; */
          /* line-height: 0px; */
          right: 50%;
          font-size: 17px;
          margin: 0 20px;
          // font-weight: bold;
        }

        .live-events-options {
          position: absolute;
          width: 100%;
          height: 100%;
          bottom: initial;
          /* bottom: 35%; */
          pointer-events: none;

          .menu {
            // top: 20px;
            top: initial;
            height: 100%;
            position: relative;
            right: initial;
            width: 100%;

            &.active {
              transform: rotate(0deg);

              svg {
                transform: rotate(180deg);
              }
            }

            svg {
              transition: 0.5s;
              position: absolute;
              right: 15px;
              top: 14px;
            }
          }

          .options {
            .options-inner {
              width: 100%;
              top: 11px;
              right: 0px;
            }
          }
        }

        // margin-right: 10px;
        // // margin-bottom: -1em;
        // padding-top: 3px;
        // border: 1px solid white;
        // width: 350px;
        // height: 60px;

        .input-wrapper {
          margin-bottom: 1.75em;
        }
      }

      // end event-options
    }

    // end right
  }

  @media screen and (max-width: 780px) {
    .event-inner {
      max-width: min-content;

      .right {
        .tix-sold {
          width: 261px;
        }

        button {
          margin-right: 0;
        }

        &::v-deep .event-options {
          margin-right: 0;
        }

        margin-right: 20px;
        margin-bottom: 10px;
      }
    }
  }
}

@media screen and (max-width: 456px) {
  .live-event {
    .event-inner {
      .right {
        .tix-sold {
          width: 100%;
        }

        button {
          margin-left: 0;
          width: 100%;
        }
      }

      .event-details {
        .brand-name {
          font-size: 1em;
        }

        .event-date {
          font-size: 0.9em;
        }

        .creation-date {
          font-size: 0.7em;
        }
      }
    }
  }
}
</style>

<script>
import DiyoboInput from "@/components/DiyoboInput.vue";
import EventOptions from "@/components/EventOptions.vue";
import dateFormat from "dateformat";
import LeaveBrandModal from "../../components/brand/LeaveBrandModal.vue";
import ModalContent from "@/helpers/modals";

export default {
  name: "brand-tiles",
  components: {
    DiyoboInput,
    EventOptions,
    LeaveBrandModal
  },
  props: {
    name: String,
    latestDate: Array,
    created: Date,
    totalEvents: Number,
    url: String,
    brand: Object
  },
  computed: {
    brandImage() {
      let brand = this.brand;
      if (!brand.pageInfo || !brand.pageInfo.profileImage) {
        return "/img/placeholders/noImage.png";
      } else {
        return process.env.VUE_APP_IMAGE_URL + brand.pageInfo.profileImage;
      }
    },
    creationDate() {
      let brand = this.brand;
      if (brand.created && brand.created.$date) {
        return dateFormat(
          +brand.created.$date.$numberLong || "",
          "mmm dS, yyyy"
        );
      } else {
        return null;
      }
    },
    latestEventDate() {
      let latestDate = this.latestDate;
      if (latestDate.length > 0) {
        return dateFormat(
          +latestDate[0].start.$date.$numberLong || "",
          "mmm dS, yyyy"
        );
      } else {
        return null;
      }
    }
  },

  data() {
    return {
      options: [
        {
          label: "Brand Financials",
          value: "brand-financials",
          icon: "icon-money.png",
          type: "external",
          link: "/financials",
          inactive: false,
          permission: "BRAND_FINANCIALS"
        },
        // {
        //   label: "Verification",
        //   value: "4",
        //   icon: "icon-copy.png",
        //   type: "external",
        //   link: "/verification",
        //   inactive: false,
        //   permission: "CREATE_EVENT"
        // },
        {
          label: "Brand Info",
          value: "3",
          icon: "icon-edit.png",
          type: "1",
          link: "/brands/edit-brand",
          inactive: false,
          permission: "BRAND_INFO"
        },
        {
          label: "Payouts",
          value: "11",
          icon: "icon-print.png",
          type: "1",
          link: "/brands/payout",
          inactive: false,
          permission: "PAYOUTS"
        },
        {
          label: "Brand Page",
          value: "2",
          icon: "icon-automation.png",
          type: "1",
          link: "/brand-page",
          inactive: false,
          permission: "BRAND_PAGE"
        },
        {
          label: "User Permissions",
          value: "5",
          icon: "icon-user.png",
          type: "1",
          link: "/brands/user-permissions",
          inactive: false,
          permission: "USER_PERMISSIONS"
        },
        {
          label: "Brand Stats",
          value: "6",
          icon: "icon-chart.png",
          type: "1",
          link: "/brands/stats",
          inactive: false,
          permission: "BRAND_STATS"
        },
        {
          label: "Events",
          value: "dashboard",
          icon: "icon-event.png",
          type: "external",
          link: "/dashboard",
          inactive: false
        },
        {
          label: "Default Taxes",
          value: "6",
          icon: "icon-payment.png",
          type: "1",
          link: "/brands/taxes",
          inactive: false,
          permission: "BRAND_TAXES"
        },
        {
          label: "Embed",
          value: "6",
          icon: "icon-code.png",
          type: "1",
          link: "/brands/embed",
          inactive: false,
          permission: "BRAND_TAXES"
        },
        {
          label: "Subscriptions",
          value: "8",
          icon: "icon-money.png",
          type: "1",
          link: "/brands/subscriptions",
          inactive: true,
          permission: " "
        },
        {
          label: "Brand Buyers",
          value: "6",
          icon: "icon-chart.png",
          type: "1",
          link: "/brands/buyers-list",
          inactive: false,
          permission: "BRAND_STATS"
        }
      ],
      addedBrandOptions: [
        {
          label: "Brand Financials",
          value: "brand-financials",
          icon: "icon-money.png",
          type: "external",
          link: "/financials",
          inactive: false,
          permission: "BRAND_FINANCIALS"
        },
        {
          label: "Brand Info",
          value: "3",
          icon: "icon-edit.png",
          type: "1",
          link: "/brands/edit-brand",
          inactive: false,
          permission: "BRAND_INFO"
        },
        {
          label: "Payouts",
          value: "11",
          icon: "icon-print.png",
          type: "1",
          link: "/brands/payout",
          inactive: false,
          permission: "PAYOUTS"
        },
        {
          label: "Brand Page",
          value: "2",
          icon: "icon-automation.png",
          type: "1",
          link: "/brand-page",
          inactive: false,
          permission: "BRAND_PAGE"
        },
        {
          label: "User Permissions",
          value: "5",
          icon: "icon-user.png",
          type: "1",
          link: "/brands/user-permissions",
          inactive: false,
          permission: "USER_PERMISSIONS"
        },
        {
          label: "Brand Stats",
          value: "6",
          icon: "icon-chart.png",
          type: "1",
          link: "/brands/stats",
          inactive: false,
          permission: "BRAND_STATS"
        },
        {
          label: "Events",
          value: "dashboard",
          icon: "icon-event.png",
          type: "external",
          link: "/dashboard",
          inactive: false
        },
        {
          label: "Default Taxes",
          value: "6",
          icon: "icon-payment.png",
          type: "1",
          link: "/brands/taxes",
          inactive: false,
          permission: "BRAND_TAXES"
        },
        {
          label: "Brand Buyers",
          value: "6",
          icon: "icon-chart.png",
          type: "1",
          link: "/brands/buyers-list",
          inactive: false,
          permission: "BRAND_STATS"
        },
        {
          label: "Leave Brand",
          value: "6",
          icon: "icon-cancel-white.svg",
          type: "cancel",
          link: "",
          inactive: false
        }
      ]
    };
  },
  methods: {
    changeBrand() {
      this.$store.dispatch("changeBrand", {
        name: this.brand.companyInfo.companyName,
        subdomain: this.brand.pageInfo.subdomain
      });

      this.$toast.success(
        `${this.brand.companyInfo.companyName} is set as active brand.`
      );
    },
    redirect(val) {
      switch (val) {
        case "brand-financials":
          this.$bus.$emit("alert", ModalContent.brandFinancialsWork);
          break;
        case "brand-page":
          this.$router.push("/brand-page/" + this.url);
          break;
        case "dashboard":
          this.changeBrand();
          this.$router.push("/dashboard");
        default:
          break;
      }
    },
    onLeave(url) {
      this.$refs.leaveModal.open({
        event: url,
        onConfirm: () => {
          this.leaveBrand();
        }
      });
    },
    leaveBrand() {
      let permissionId = this.brand.permissionId.$oid;

      this.$axios
        .post("/permissions/user/delete", { permissionsID: permissionId })
        .then(({ data }) => {
          this.$emit("delete", permissionId);

          this.$toast.success("Additional Brand Deleted.");
        });
    }
  },
  created() {
    // user permissions
    if (this.brand.permissions) {
      for (const option of this.addedBrandOptions) {
        const permission = this.brand.permissions[option.permission];

        if (permission !== undefined && !option.inactive) {
          option.inactive = !permission;
        }
      }
    }
  }
};
</script>
