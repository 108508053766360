<template>
  <div>
    <div class="button-container">
      <diyobo-button
        txt="Add Brand"
        class="primary"
        @click="$router.push({ name: 'add-brand' }, { target: '_blank' })"
      />
    </div>
    <div class="page-tabs">
      <tab-tote
        :tabs="navTabs"
        :value="navTabSelected"
        v-on:click="onClickNavTab"
      />
      <div class="divider" />
    </div>
    <router-view />
  </div>
</template>

<script>
import TabTote from "@/components/TabTote.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";

export default {
  name: "brand-dashboard",
  components: {
    DiyoboButton,
    TabTote
  },
  head() {
    return {
      title: "Brand"
    };
  },
  data() {
    return {
      navTabs: ["My Brands", "Added Brands"]
    };
  },
  computed: {
    navTabSelected() {
      const path = this.$route.path;
      if (path.endsWith("/my-brand")) {
        return 0;
      } else if (path.endsWith("additional-brand")) {
        return 1;
      }
    }
  },
  created() {
    this.$store.commit("setTitle", "Brand Dashboard");
    this.$axios.post("/invites").then(response => {
      const data = response.data;
      this.invites = data.invites;
    });
  },
  mounted() {
    this.$store.dispatch(
      "updateHelpVideoSrc",
      "https://www.youtube.com/embed/gmUkPdOhC7A"
    );
  },

  methods: {
    onClickNavTab(index) {
      const path = this.$route.path;
      if (index === 0 && !path.endsWith("/my-brand")) {
        this.$router.push(`/brands/my-brand`);
      } else if (index === 1 && !path.endsWith("/additional-brand")) {
        this.$router.push(`/brands/additional-brand`);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.button-container {
  margin: 18px auto;
  display: flex;
  justify-content: center;
  width: 94%;
  max-width: 1100px;
}
.page-tabs {
  margin: auto;
  width: 94%;
  max-width: 1100px;
}
</style>